import { useState } from "react";
import logoUniverso from "../img/01_LOGO_UNIVERSO.svg";
import logoPolygon from "../img/logo_polygon.png";

export default function Login({doLogin}) {
	const [userName,setUserName] = useState(null);
	const userNameChangeHandler = (ev)=>{
		setUserName(ev.target.value)
	}
	return (
		<div id="contenedor-login">
			<div className="contenedor-logo">
				<img id="logo-universo" src={logoUniverso} />
			</div>

			<div className="contenedor-datos">
				<form method="post" className="formulario-login" onSubmit={(ev)=>{ev.preventDefault();doLogin(userName)}}>
					<div className="contenedor-cabecera">
						<span className="titulo-login">¡Hola!</span>
						<span className="login-form-heading">Inicia tu sesión</span>
					</div>

					<div className="contenedor-formulario">
						<input
							type="text"
							id="user"
							name="user"
							className="form-control"
							placeholder="Nombre de usuario"
							required
							autoFocus
							onKeyUp={userNameChangeHandler}
						/>
						<input
							type="password"
							id="password"
							name="password"
							className="form-control"
							placeholder="Contraseña"
							required
						/>
					</div>

					{/* <div id="reset-password" className="contenedor-reset-contraseña">
						<a href="/request-password-reset" className="reset-contraseña">
							¿Has olvidado tu contraseña?
						</a>
					</div> */}

					<div className="contenedor-btn" onClick={(ev)=>{
						doLogin(userName)
					}}>
						<button className="btn-acceder" type="submit">
							ACCEDER
						</button>
					</div>

					<div id="google-login" className="row text-center padding-top">
						<div className="col-10 offset-1">
							{/* <a href="{{ loginWithGoogleUrl }}" class="text-center">
                            <button class="btn btn-light">
                                <img src="/assets/img/icono_google.png"/>
                                Continuar con Google
                            </button>
                        </a> */}
						</div>
					</div>

					<div className="contenedor-logo-polygon">
						<img
							id="logo-polygon"
							src={logoPolygon}
							className="img-fluid"
							alt="Polygon Education"
						/>
					</div>
				</form>
			</div>
		</div>
	);
}
