import React, {Suspense} from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";

export default function Menu({ datosPalabra }) {
	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	return (
		<>
			<nav className="menu-docente">
				<div className="logoUniverso"></div>
				<div className="contenedorEnlaces">
					<NavLink to="/">Inicio</NavLink>
					<NavLink to="/escribir">Para escribir</NavLink>
					<NavLink to="/juegos">Juegos</NavLink>
				</div>
			</nav>

			<Suspense fallback={<div>Cargando...</div>}>
		  		<Outlet />
			</Suspense>
			{splitLocation[1] === "" && (
				<div className="contenedorTextoBienvenida">
					<h1 className="titulo">¡Hola, familias!</h1>
					<p>
						En este espacio web, entrando en el menú superior, tenéis acceso a
						los juegos de <em>El Universo de Clin</em>, para que podáis jugar en
						casa con los planetas que visitaremos este curso
						en el aula.
					</p>
					<p>¡Que lo disfrutéis!</p>
					<p>
						¡Ah! y si queréis saber un poquito más sobre{" "}
						<em>El Universo de Clin,</em> podéis entrar en el siguiente enlace:{" "}
						<a
							href="https://media.polygoneducation.com/udc/Item_22_folleto_familias_PACK%20DIGITAL.pdf"
							target="blank"
						>
							Guía para las familias
						</a>
					</p>
				</div>
			)}
		</>
	);
}
