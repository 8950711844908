import React, { useState } from "react";
import {
	Route,
	Routes,
	HashRouter
} from "react-router-dom";

import Menu from "./Menu";
import Login from "./Login";

const IndiceLetras = React.lazy(() =>  import("para-escribir").then(module => ({ default: module.IndiceLetras })))
const ParaEscribirLetra = React.lazy(() => import("./ParaEscribirLetra"));
const IndiceJuegos = React.lazy(() => import("juegos").then(module => ({ default: module.IndiceJuegos })));
const PantallaConfig = React.lazy(() => import("juegos").then(module => ({ default: module.PantallaConfig })));
const Juego = React.lazy(() => import("./Juego"));

export default function App() {
	const [loguedInUser, setLoguedInUser] = useState(sessionStorage.getItem("userName"))

	const doLogin = function (userName) {
		sessionStorage.setItem("userName",userName)
		setLoguedInUser(userName)
	};

	return (
		<>
			{loguedInUser === null && (
				<Login doLogin={doLogin}/>
			)}
			{loguedInUser !== null && (
				<HashRouter>
					<Routes>
						<Route path="/" element={<Menu />}>
							<Route path="/escribir">
								<Route index element={<IndiceLetras/>} />
								<Route path=":letra" element={<ParaEscribirLetra/>} />
							</Route>
		
							<Route path="/juegos">
								<Route index element={<IndiceJuegos/>} />
								<Route path="config" element={<PantallaConfig />} />
								<Route path=":juego" element={<Juego/>} />

							</Route> 
		
						</Route>
					</Routes>
				</HashRouter>
			)}
		</>
	);
}
